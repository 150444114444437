import React, { Component } from 'react';
import axios from "axios";

import Button from './Button';

import styles from "./ClapsButton.module.css";
import { cn } from '../../util';

const CLAPS_API = "https://europe-west2-dockington.cloudfunctions.net/claps";

const getClapCount = function (claps) {
    if (claps < 1) {
        return 0;
    } else if (claps < 1000) {
        return claps;
    } else if (claps < 10000) {
        return (claps / 1000).toFixed(1) + "k";
    } else {
        return (claps / 1000).toFixed(0) + "k";
    }
}

class ClapsButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claps: -1,
            clapped: 0,
        };
    }

    componentDidMount() {
        axios.post(CLAPS_API, {
            slug: this.props.slug,
        })
        .then((res) => {
            if (res.status === 200 && typeof res.data.claps === "number") {
                this.setState({ claps: res.data.claps });
            } else {
                if (window.gtag) {
                    window.gtag('event', "get-claps-error", {
                        'event_category' : 'claps',
                        'event_label' : `Get claps failed with response ${res.status} ${res.data.claps}`,
                    });
                }
                this.setState({ claps: 0 });
            }
        })
        .catch((err) => {
            if (window.gtag) {
                window.gtag('event', "get-claps-error", {
                    'event_category' : 'claps',
                    'event_label' : `Get claps failed${err.response ? ` with response ${err.response.status}` : ""}`,
                });
            }
            this.setState({ claps: 0 });
        });
    }

    componentWillUnmount() {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = undefined;
            if (this.state.clapped > 0) {
                this.sendUpdate();
            }
        }
    }

    sendUpdate = () => {
        this.updateTimeout = undefined;
        const prevClaps = this.state.claps > 0 ? this.state.claps : 0;
        const claps = this.state.clapped;
        if (window.gtag) {
            window.gtag('event', "give-claps", {
                'event_category' : 'claps',
                'event_label' :  `Give ${claps} to blog ${this.props.slug}`,
            });
        }
        this.setState({ claps: prevClaps + claps, clapped: 0 });
        axios.post(CLAPS_API, {
            slug: this.props.slug,
            claps,
        })
        .then((res) => {
            if (res.status === 200 && typeof res.data.claps === "number") {
                this.setState({ claps: res.data.claps });
            } else {
                if (window.gtag) {
                    window.gtag('event', "give-claps-error", {
                        'event_category' : 'claps',
                        'event_label' : `Give claps failed with response ${res.status} ${res.data.claps}`,
                    });
                }
            }
        })
        .catch((err) => {
            if (window.gtag) {
                window.gtag('event', "give-claps-error", {
                    'event_category' : 'claps',
                    'event_label' : `Give claps failed${err.response ? ` with response ${err.response.status}` : ""}`,
                });
            }
        });
    }

    clap = () => {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = undefined;
        }
        this.setState({ clapped: this.state.clapped + 1 });
        this.updateTimeout = setTimeout(this.sendUpdate, 1000);
    }

    render() { 
        return (
            <Button className={styles.clapsButton} onClick={this.clap}>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.9544 40.3279C22.9544 40.3279 27.2471 44.7605 32.1047 44.9828C36.9623 45.2051 41.6321 40.3172 42.1415 35.4933C42.6508 30.6695 40.002 23.8682 40.002 23.8682L35.1609 16.0396C33.8668 13.2573 30.7163 14.9386 31.5009 17.5926L33.0657 24.3199L21.1938 10.85C19.2274 9.49487 16.6204 11.7927 17.9908 13.673L16.2132 11.656C14.2468 10.3009 11.4225 12.7902 12.5198 14.9112L13.4134 15.9251C11.447 14.57 8.69508 16.9954 9.79242 19.1164L12.537 22.2305C10.5706 20.8754 8.32564 22.854 9.42299 24.975L22.9544 40.3279Z" fill="#FAE0C0"/>
                    <path d="M27.3843 24.3309L16.2132 11.656C14.2468 10.3009 11.4225 12.7902 12.5198 14.9112L23.3609 27.2117L13.4134 15.9251C11.447 14.57 8.69508 16.9954 9.79242 19.1164L20.3144 31.0548L12.537 22.2305C10.5706 20.8754 8.32564 22.854 9.42299 24.975L22.9544 40.3279C22.9544 40.3279 27.2471 44.7605 32.1047 44.9828C36.9623 45.2051 41.6321 40.3172 42.1415 35.4933C42.6508 30.6695 40.002 23.8682 40.002 23.8682L35.1609 16.0396C33.8668 13.2573 30.7163 14.9386 31.5009 17.5926L33.0657 24.3199L34.5337 25.9856L21.1938 10.85C19.2274 9.49487 16.6204 11.7927 17.9908 13.673" stroke="#666666" strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M19.1316 41.2638C19.1316 41.2638 23.3428 45.7738 28.1955 46.0847C33.0482 46.3955 37.8064 41.5936 38.4036 36.7798C39.0009 31.966 36.4765 25.1176 36.4765 25.1176L31.7789 17.202C30.5357 14.3966 27.3551 16.0202 28.0912 18.6881L29.5331 25.4428L17.9087 11.7587C15.9674 10.3679 13.3188 12.6178 14.6548 14.5228L12.9142 12.4738C10.9729 11.083 8.10362 13.5204 9.16211 15.6611L10.0371 16.6911C8.09572 15.3004 5.30003 17.6752 6.35852 19.8159L9.04588 22.9795C7.10453 21.5887 4.82385 23.5261 5.88235 25.6668L19.1316 41.2638Z" fill="#FAE0C0"/>
                    <path d="M23.8525 25.3502L12.9142 12.4738C10.9729 11.083 8.10362 13.5204 9.16211 15.6611L19.7772 28.1571L10.0371 16.6911C8.09572 15.3004 5.30003 17.6752 6.35852 19.8159L16.6611 31.9441L9.04588 22.9795C7.10453 21.5887 4.82385 23.5261 5.88235 25.6668L19.1316 41.2638C19.1316 41.2638 23.3428 45.7738 28.1955 46.0847C33.0482 46.3955 37.8064 41.5936 38.4036 36.7798C39.0009 31.966 36.4765 25.1176 36.4765 25.1176L31.7789 17.202C30.5357 14.3966 27.3551 16.0202 28.0912 18.6881L29.5331 25.4428L30.9705 27.1349L17.9087 11.7587C15.9674 10.3679 13.3188 12.6178 14.6548 14.5228" stroke="#666666" strokeWidth="0.9375" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M15.4901 2.34708L14.4745 5.74265L12.1 3.11147C12.7863 1.90121 14.3509 1.54842 15.4901 2.34708Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                    <path d="M20.8266 5.24527L17.4997 6.46717L18.183 2.98949C19.5631 2.81355 20.7832 3.85468 20.8266 5.24527Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                    <path d="M10.2631 4.91402L12.2954 7.81764L8.75864 8.04664C8.2355 6.75747 8.92988 5.31165 10.2631 4.91402Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                    <path d="M40.2597 10.209L36.8997 11.3367L37.6807 7.87962C39.0652 7.74261 40.2555 8.81769 40.2597 10.209Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                    <path d="M40.968 16.2401L38.0516 14.2262L41.2872 12.7797C42.2266 13.8059 42.0793 15.4031 40.968 16.2401Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                    <path d="M35.1489 7.41781L33.948 10.7523L31.7219 7.99446C32.4737 6.82381 34.0554 6.55766 35.1489 7.41781Z" fill="#C7C7C7" stroke="#ABABAB" strokeWidth="0.375"/>
                </svg>

                <div className={styles.clapsCounterContainer}>
                    <div className={styles.clapsCounter}>
                        {getClapCount(this.state.claps)}
                        <div className={cn(styles.clapsGiven, this.state.clapped > 0 ? "" : styles.hide)}>
                            {`+${this.state.clapped}`}
                        </div>
                    </div>
                </div>
            </Button>
        );
    }
}
 
export default ClapsButton;
