import React from "react";

import "./CheckboxButton.css";

export interface CheckboxButtonProps {
    id: string,
    value?: boolean,
    onChange?: (value: boolean) => void,
}

const CheckboxButton: React.FunctionComponent<CheckboxButtonProps> = function ({ value, id, onChange, children }) {
    const [ selected, setSelected ] = React.useState(!!value);
    const onClick = (ev: any) => {
        setSelected(!selected);
        if (onChange) {
            onChange(!selected);
        }
        ev.preventDefault();
    }
    return (
        <div className={"form-group form-check checkbtn" + (selected ? " checked" : "")} onClick={onClick}>
            <input
                type="checkbox"
                className="form-check-input"
                id={id}
                checked={selected}
                onChange={onClick}
            />
            <label className="form-check-label" htmlFor={id}>{children}</label>
        </div>
    )
}

export default CheckboxButton;
