import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "gatsby-image";

import Example from "./Example";

import commonProcessText from "../../views/processText";
import Col from "../../components/common/Col";
import Row from "../../components/common/Row";
import List from "../../components/common/List";
import AutoPlayInViewVideo from "../../components/common/AutoPlayInViewVideo";

import { cn } from "../../util";

export const processText = function (text, id) {
    if (Array.isArray(text)) {
        return text.map((piece, i) => processText(piece, id + `a${i}`));
    } else if (text && typeof text === "object") {
        if (text.list) {
            return (
                <BulletedList
                    list={text.list}
                    id={id}
                />
            );
        }
    }
    return commonProcessText(text);
};

const Text = function ({content, className}) {
    return (
        <div className={className}>
            {content.map((piece, i) => (
                <p key={i}>{processText(piece, i)}</p>
            ))}
        </div>
    );
};

const TextWithIcon = function ({icon, content, className}) {
    return (
        <div className={className}>
            <span className="inline-icon">
                <FontAwesomeIcon icon={icon} />
            </span>
            {processText(content)}
        </div>
    );
};

const BulletedList = function ({content, id}) {
    return (
        <ul key={`${id || "item"}:list`}>
            {content.map((item, i) => (
                <li style={{marginTop: "0.5em"}} key={i}>{item.title && <span style={{ fontWeight: "bold", marginRight: "0.5em" }}>{item.title}</span>}{processText(item.content ? item.content : item, i)}</li>
            ))}
        </ul>
    );
}

const Section = function ({ blog, content }) {
    return (
        <div className="section pt-1">
            {
                content.map((element) => (
                    <Block blog={blog} {...element} />
                ))
            }
        </div>
    );
};

const BigTitle = function ({content}) {
    return (
        <h2>{content}</h2>
    );
};
const SmallTitle = function ({content}) {
    return (
        <h3>{processText(content)}</h3>
    );
};

const Disclaimer = function ({content}) {
    return (
        <p className="text-muted">{processText(content)}</p>
    );
};

const NumberedList = function ({ content, className }) {
    return (
        <List
            className={cn("mb-1", className)}
            items={content.map((item, idx) => ({
                marker: `${idx + 1}.`,
                text: processText(item),
            }))}
        />
    );
};

const TextWithImage = function ({ title, image, content, className }) {
    return (
        <Row className={cn("pl-sm-1",className)}>
            <Col xs12 s6 m7 className="py-sm-1 bl-sm">
                {title && <h3>{title}</h3>}
                {content.map((part, i) => (
                    <div key={i}>{processText(part)}</div>
                ))}
            </Col>
            <Col xs12 s6 m5 className="mt-1 mt-md-0">
                <img
                    src={image}
                    style={{ width: "100%" }}
                />
            </Col>
        </Row>
    )
}

const Examples = function ({blog, title, content, className}) {
    return (
        <div className={className}>
            <h2>{title}</h2>
            <div>
                {content.map((ex, i) => (
                    <Example
                        className="pt-1"
                        blog={blog}
                        example={ex}
                        exampleIdx={i}
                    />
                ))}
            </div>
        </div>
    );
};

const ImageBlock = function ({ blog, content, className, imgClassName, caption }) {
    const img = blog.images.find((img) => (img.name === content));
    return (
        <div className={cn("px-sm-1 px-md-2 px-xl-0", className)} style={{ textAlign: "center" }}>
            <Image
                fluid={img.image}
                alt={img.alt}
                title={img.alt}
                className={cn("w-100 w-sm-90 w-xl-75 mx-auto", imgClassName)}
            />
            {caption && <div className="caption">
                {processText(caption)}
            </div>}
        </div>
    );
};

const VideoBlock = function ({ blog, content, className, videoClassName }) {
    const video = blog.videos.find((video) => (video.name === content));
    return (
        <div className={cn("px-sm-1 px-md-2 px-xl-0", className)} style={{ textAlign: "center" }}>
            <AutoPlayInViewVideo className={cn("img-shadow w-100 w-sm-90 w-xl-75 mx-auto", videoClassName)}>
                {video.src.map((src, idx) => (
                    <source key={idx} src={src.src} type={src.type}/>
                ))}
            </AutoPlayInViewVideo>
        </div>
    );
};

const TitleWithIcon = function ({ icon, content }) {
    return (
        <div className="title-with-icon">
            {icon && <div className="title-icon">
                <FontAwesomeIcon size="lg" icon={icon}/>
            </div>}
            <h2>
                {processText(content)}
            </h2>
        </div>
    );
};

const Block = function (props) {
    const { type } = props;
    switch (type) {
        case "h2": return (<BigTitle {...props} />);
        case "h3": return (<SmallTitle {...props} />);
        case "text": return (<Text {...props} />);
        case "textWithIcon": return (<TextWithIcon {...props} />);
        case "bulletedList": return (<BulletedList {...props} />);
        case "examples": return (<Examples {...props} />);
        case "section": return (<Section {...props} />);
        case "disclaimer": return (<Disclaimer {...props} />);
        case "textWithImage": return (<TextWithImage {...props} />);
        case "list": return (<NumberedList {...props} />);
        case "image": return (<ImageBlock {...props} />);
        case "video": return (<VideoBlock {...props} />);
        case "titleWithIcon": return (<TitleWithIcon {...props} />);
    }
};

export default Block;
