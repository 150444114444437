import React from "react";

import "./RadioGroup.css";

export interface RadioGroupProps {
    options: {
        label: string,
        value: string,
    }[],
    inline?: boolean,
    value?: string,
    name?: string,
    onChange?: (idx: number) => void,
}

const RadioButton: React.FunctionComponent<any> = function ({ label, value, checked, name, onSelect }) {
    return (
        <button className={`radiobtn${checked ? " checked" : ""}`} onClick={onSelect}>
            <div key={label} className="form-check">
                <input
                    className="form-check-input"
                    type="radio"
                    name={name || "radio"}
                    id={value}
                    value={value}
                    checked={!!checked}
                />
                <label className="form-check-label" htmlFor={value}>{label}</label>
            </div>
        </button>
    );
}

const RadioGroup: React.FunctionComponent<RadioGroupProps> = function ({ options, inline, value, name, onChange }) {
    const [ selected, setSelected ] = React.useState(value);
    if (!name) { name = "radio" + Math.floor(Math.random() * 10000) }
    return (
        <form className={`radiogroup${inline ? " inline" : ""}`} onSubmit={(ev) => (ev.preventDefault())}>
            {options.map((opt, idx) => (
                <RadioButton
                    {...opt}
                    name={name}
                    checked={opt.value === selected}
                    onSelect={() => {
                        if (opt.value !== selected) {
                            setSelected(opt.value);
                            if (onChange) {
                                onChange(idx);
                            }
                        }
                    }}
                />
            ))}
        </form>
    );
}

export default RadioGroup;
