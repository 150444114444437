import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";

import Layout from "../components/Layout";
import withTranslate from "../components/translate";
import SEO from "../components/SEO";
import ScrollToTopOnMount from "../components/common/ScrollToTopOnMount";
import NavBar from "../components/common/NavBar";
import Row from "../components/common/Row";
import Col from "../components/common/Col";
import Button from "../components/common/Button";
import Link from "../components/common/Link";
import ClapsButton from "../components/common/ClapsButton";
import HelloBar from "../components/common/HelloBar";
import DrOptInForm from "../views/DockingtonReports/DrOptInForm";

import BlogCard from "./BlogCard";
import Block from "./blocks";

import "./Blog.css";
import { DEFAULT_LOCALE } from "../translations";

export const query = graphql`
    query($slug: String!) {
        blogsJson(slug: {eq:$slug}) {
            taglineImage {
                childImageSharp {
                    fluid(quality: 90) {
                        ...GatsbyImageSharpFluid
                    }
                    original {
                      src
                    }
                }
            }
            taglineImageAlt {
                en
                it
            }
            authorIcon {
                childImageSharp {
                    original {
                        src
                    }
                }
            }
            author
            date
            readingTime
            title {
              locale
              text
            }
            snippet {
              locale
              text
            }
            keywords {
              locale
              keywords
            }
            images {
                name
                image {
                    childImageSharp {
                        fluid(maxWidth: 1200, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                alt {
                    en
                    it
                }
            }
            videos {
                name
                src {
                    src
                    type
                }
            }
        }
    }
`;

const getFaqScript = function (blog) {
    let qna = blog.faqPage;
    if (!qna || !Array.isArray(qna)) return undefined;
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": qna.map((qa) => ({
            "@type": "Question",
            "name": qa.q,
            "acceptedAnswer": {
                "@type": "Answer",
                "text": Array.isArray(qa.a) ? qa.a.reduce((text, a) => {
                    return text + `<p>${a}</p>`;
                }, "") : qa.a,
            }
        }))
    }, null, 2);
}

const Blog = withTranslate(({ pagePath, blog, translate, locale, localePath }) => {
    const faqScript = getFaqScript(blog);
    return (
        <div className="blog-page">
            <SEO
                title={blog.title}
                description={blog.snippet}
                keywords={blog.keywords}
                path={pagePath}
                translate={translate}
                meta={[
                    {
                        property: "og:image",
                        content: `https://www.dockington.com${blog.taglineImageOriginal}`,
                    },
                    {
                        property: "og:url",
                        content: `https://www.dockington.com${localePath}${pagePath}`,
                    }
                ]}
            >
                <script type="application/ld+json">
{`{
	"@context": "http://schema.org",
	"@type": "BlogPosting",
    "publisher": {
        "@type": "Organization",
        "name": "CheekSoft Oy",
        "logo": {
        	"@type": "ImageObject",
        	"url": "http://www.dockington.com/images/logo.png"
        },
        "brand": {
            "@type": "Brand",
            "name": "Dockington",
            "logo": {
                "@type": "ImageObject",
                "url": "http://www.dockington.com/images/logo.png"
            }
        }
    },
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://www.dockington.com${localePath}${pagePath}"
    },
    "author": {
        "@type": "Person",
        "name": "${blog.author}"
    },
    "headline": "${blog.title}",
    "description": "${blog.snippet}",
    "datePublished": "${blog.date}",
    "dateModified": "${blog.lastModified || blog.date}",
    "image": "https://www.dockington.com${blog.taglineImageOriginal}"
}`}
                </script>
                {faqScript && <script type="application/ld+json">
                    {faqScript}
                </script>}
            </SEO>
            <ScrollToTopOnMount />
            <NavBar translate={translate}>
                {(blog.hellobar !== false && (blog.hellobar || locale === "it")) &&
                    <HelloBar
                        renderForm={({ onSubscribe }) => (
                            <DrOptInForm
                                onSubscribe={onSubscribe}
                                content={translate("blogs.subscribe.miniguide")}
                            />
                        )}
                    />
                }
            </NavBar>
            <div className="container">
                <Row justify="center">
                    <Col x12 m10 l8 className="pt-5">
                        <h1>{blog.title}</h1>
                        <header className="pt-1 pb-2">
                            <Image
                                fluid={blog.taglineImage}
                                alt={blog.taglineImageAlt}
                            />
                            <div className="author">
                                <span className="inline-icon">
                                    <img
                                        className="author-icon"
                                        src={blog.authorIcon}
                                        alt={blog.author}
                                    />
                                </span> {blog.author}<span className="reading-time">{blog.readingTime} min</span>
                            </div>
                        </header>
                        <div>
                            {Array.isArray(blog.body) &&
                                blog.body.map((element, idx) => (
                                    <Block
                                        key={idx}
                                        blog={blog}
                                        {...element}
                                    />
                                ))
                            }
                        </div>
                        {blog.action && <Row justify="center" className="pt-3">
                            <div>
                                {blog.action.title && <h2 className="text-center mb-3" style={{ fontWeight: "bold" }}>{blog.action.title}</h2>}
                                <Row justify="center" className="px-3">
                                    <Link to={blog.action.link ? blog.action.link : "/"} onClick={() => {
                                        if (window.gtag) {
                                            window.gtag('event', `blog-cta`, {
                                                'event_category' : 'blog-cta',
                                                'event_label' : `Blog CTA ${blog.slug}`
                                            });
                                        }
                                    }}>
                                        <Button style={{ padding: "0.75em 1.25em", fontWeight: "bold", borderRadius: "6px" }} success>
                                            {blog.action.button}
                                        </Button>
                                    </Link>
                                </Row>
                            </div>
                        </Row>}

                        <div className="pt-2">
                            <div className="text-center">
                                <small style={{ color: "#666" }}>{translate("blogs.share")}</small>
                            </div>
                            <Row className="mx-0 pt-1" justify="center">
                                <div className="px-1">
                                    <ClapsButton slug={blog.slug} />
                                </div>
                                <div className="px-1 pt-1">
                                    <iframe
                                        src={`https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fwww.dockington.com${encodeURIComponent(localePath)}%2Fblog%2F${blog.slug}&layout=button_count&size=large&width=91&height=28&appId`}
                                        width="91"
                                        height="28"
                                        style={{ border:"none", overflow: "hidden" }}
                                        title={translate("common.shareOnFacebook")}
                                        scrolling="no"
                                        frameborder="0"
                                        allowTransparency="true"
                                        allow="encrypted-media"
                                    />
                                </div>
                            </Row>
                        </div>

                        <div className="readmore">
                            <h2 className="text-center">{translate("blogs.readmore")}</h2>
                            <Row>
                                <Col xs12 s5 m4 orderXs={2} orderS={1} className="mt-2 mt-sm-0">
                                    {blog.previousBlog &&
                                        <BlogCard
                                            blog={blog.previousBlog}
                                            previous
                                            translate={translate}
                                        />
                                    }
                                </Col>
                                <Col hideXs s2 m4 orderXs={3} orderS={2}/>
                                <Col xs12 s5 m4 orderXs={1} orderS={3}>
                                    {blog.nextBlog &&
                                        <BlogCard
                                            blog={blog.nextBlog}
                                            translate={translate}
                                        />
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
});

const matchLocale = function (locale) {
    if (!locale) {
        locale = DEFAULT_LOCALE;
    }
    return (item) => (item.locale === locale);
}

const BlogContainer = function ({ location, data, pageContext: { pagePath, locale, slug, blog, previousBlog, nextBlog } }) {
    return (
        <Layout location={location} locale={locale}>
            <Blog
                pagePath={pagePath}
                blog={{
                    ...blog,
                    slug,
                    author: data.blogsJson.author,
                    date: data.blogsJson.date,
                    readingTime: data.blogsJson.readingTime,
                    title: data.blogsJson.title.find(matchLocale(locale)).text,
                    snippet: data.blogsJson.snippet.find(matchLocale(locale)).text,
                    keywords: data.blogsJson.keywords.find(matchLocale(locale)).keywords,
                    taglineImage: data.blogsJson.taglineImage.childImageSharp.fluid,
                    taglineImageAlt: data.blogsJson.taglineImageAlt ? data.blogsJson.taglineImageAlt[locale] : "",
                    taglineImageOriginal: data.blogsJson.taglineImage.childImageSharp.original.src,
                    authorIcon: data.blogsJson.authorIcon.childImageSharp.original.src,
                    images: (data.blogsJson.images || []).map((img) => ({ name: img.name, image: img.image.childImageSharp.fluid, alt: img.alt[locale] })),
                    videos: data.blogsJson.videos || [],
                    previousBlog,
                    nextBlog,
                }}
            />
        </Layout>
    );
}

export default BlogContainer;
