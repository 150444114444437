import React from "react";
import Image from "gatsby-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Row from "../components/common/Row";
import Link from "../components/common/Link";

import "./BlogCard.css";

const BlogCard = function ({ blog, previous, translate }) {
    return (
        <Link to={"/blog/" + blog.slug}>
            {previous ?
            <div style={{ color: "black" }}><FontAwesomeIcon icon="arrow-left" style={{ marginRight: "0.5em" }} /> {translate("blogs.previous")}</div>
            :
            <div className="text-right" style={{ color: "black" }}>{translate("blogs.next")} <FontAwesomeIcon icon="arrow-right" style={{ marginLeft: "0.5em" }}/></div>
            }
            <div className="blog-card">
                <Image
                    fluid={blog.image}
                    alt={blog.title}
                    className="img"
                />
                <div className="blog-text">
                    <Row className="author mx-0">
                        <div>
                            <img
                                className="author-icon"
                                src={blog.authorIcon}
                                alt={blog.author}
                            />
                        </div>
                        <div>
                            <div>{blog.author}</div>
                            <div className="reading-time">{blog.readingTime} min</div>
                        </div>
                    </Row>
                    <h2>{blog.title}</h2>
                </div>
            </div>
        </Link>
    );
}

export default BlogCard;
