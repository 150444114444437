import * as React from 'react';

import OptInForm, { NameField, EmailField, SubmitButton, ErrorLabel } from '../../components/common/OptInForm';
import Row from '../../components/common/Row';
import Col from '../../components/common/Col';

import processText from '../processText';
import { cn } from '../../util';
import withTranslate from '../../components/translate';

const reports = require("../../assets/images/dockington-reports-bar-graph.jpg");
const styles = require("./DrOptInForm.module.css");

export interface DrOptInFormProps {
    content: {
        title: any,
        text: any,
        disclaimer: any,
        subscribe: any,
        subscribing: any,
    },
    signupFrom?: string,
    onSubscribe?: () => void,
    translate: (key: string) => string,
}
 
const DrOptInForm: React.SFC<DrOptInFormProps> = ({ content, signupFrom, onSubscribe, translate }) => {
    return (
        <OptInForm
            signUpUrl="https://europe-west2-dockington.cloudfunctions.net/subscribedr"
            onSubscribe={onSubscribe}
            signupFrom={signupFrom}
            translate={translate}
        >
            <Row className={cn(styles.body, "mx-0")}>
                <Col xs12 m6 className={styles.column}>
                    <h2>
                        <div className={styles.title}>{processText(content.title)}</div>
                    </h2>
                    {processText(content.text)}
                    <div className={cn(styles.disclaimer, "d-none d-md-block")}>
                        {processText(content.disclaimer)}
                    </div>
                </Col>
                <Col xs12 m6 align="center" className={cn(styles.column, styles.leftColumn)}>
                    <div>
                        <div className={styles.imgContainer}>
                            <img className={styles.img} src={reports} />
                        </div>
                        <NameField />
                        <EmailField />
                        <ErrorLabel />
                        <SubmitButton
                            className={styles.submit}
                            actionText={content.subscribe}
                            loadingText={content.subscribing}
                        />
                        <div className={cn(styles.disclaimer, "d-md-none")}>
                            {processText(content.disclaimer)}
                        </div>
                    </div>
                </Col>
            </Row>
        </OptInForm>
    );
}
 
export default withTranslate(DrOptInForm);
