import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Row from "../../components/common/Row";
import CheckboxButton from "../../components/common/CheckboxButton";
import RadioGroup from "../../components/common/RadioGroup";

import { processText } from "../blocks";

import "./Example.css";

const Example = function ({ blog, example, exampleIdx, className }) {
    return (
        <div key={example.title} className={"example" + (className ? ` ${className}` : "")}>
            <div className="example-title">
                {example.icon && <div className="example-icon">
                    <FontAwesomeIcon size="lg" icon={example.icon}/>
                </div>}
                <h3>
                    {example.case ? `${example.case} - ` : ""}{example.title}
                </h3>
            </div>
            {example.body && example.body.map((piece, i) => (
                <p key={i}>{processText(piece, i)}</p>
            ))}
            {example.action &&
            <Row justify="center" className="px-3 pb-1">
                {Array.isArray(example.action) ?
                    <Row justify="center">
                        <RadioGroup
                            name={"groupex" + exampleIdx}
                            inline={true}
                            options={example.action.map((action, ai) => ({ value: `ex${exampleIdx}a${ai}`, label: action }))}
                            onChange={(idx) => {
                                if (window.gtag) {
                                    window.gtag('event', `blog-ex${exampleIdx}-action${idx}`, {
                                        'event_category' : 'blog-ex-action',
                                        'event_label' : `Blog ${blog.slug} example ${exampleIdx} action ${value}`
                                    });
                                }
                            }}
                        />
                    </Row>
                    :
                    <CheckboxButton id={`example${exampleIdx}`} onChange={(value) => {
                        if (window.gtag) {
                            window.gtag('event', `blog-ex${exampleIdx}-action`, {
                                'event_category' : 'blog-ex-action',
                                'event_label' : `Blog ${blog.slug} example ${exampleIdx} action ${value ? "on" : "off"}`
                            });
                        }
                    }}>
                        {example.action}
                    </CheckboxButton>
                }
            </Row>}
            {example.solution && <p className="solution">
                <span className="inline-icon">
                    <FontAwesomeIcon icon="unlock-alt"/>
                </span>
                {example.solution}
            </p>}
            {example.text && example.text.map((part, pi) => (
                <p key={pi}>{processText(part)}</p>
            ))}
        </div>
    );
};

export default Example;