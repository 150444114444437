import React from 'react'

import Modal from './Modal';

import processText from '../../views/processText';
import insiders from "../../assets/images/dockington-insiders.jpg";

import styles from "./OptInThankYouModal.module.css";

const OptInThankYouModal = function ({ isOpen, onClose, modalClassName, translate }) {
    const content = translate("blogs.subscribe.thankyou");
    return (
        <Modal
            isOpen={isOpen}
            contentLabel="Grazie"
            onClose={onClose}
            className={modalClassName}
            image={{
                src: insiders,
                alt: content.imageAlt,
            }}
        >
            <div className={styles.body}>
                <h1>{content.title}</h1>
                {content.text.map((part) => (
                    <p>{processText(part)}</p>
                ))}
                <p>
                    {content.signature.greeting}<br/>
                    <span className="signature">{content.signature.sign}</span>
                </p>
                <p className="text-muted">{content.ps}</p>
            </div>
        </Modal>
    );
}

export default OptInThankYouModal;