import React, { Component } from 'react';

import Modal from './Modal';

import withTranslate from '../translate';
import { debounce } from '../../util';

const OPTIN_LOCAL_KEY = "dkt_opt";

class OptInPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: false,
            shown: false,
        }
    }

    componentDidMount() {
        let lastShow = localStorage.getItem(OPTIN_LOCAL_KEY);
        // Don't show again if last time was less than 12 hours ago
        if (lastShow && new Date().getTime() - parseInt(lastShow, 10) < 1000 * 60 * 60 * 12) {
            this.setState({ shown: true });
        }
        this.scrollListener = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.shown && !this.props.isOpen && nextProps.isOpen) {
            localStorage.setItem(OPTIN_LOCAL_KEY, new Date().getTime().toString());
            this.setState({ shown: true });
        }
    }

    handleScroll = () => {
        const body = document.body;
        const html = document.documentElement;
        const height = Math.max(body.scrollHeight, html.scrollHeight);
        const scrollPercent = (window.scrollY + window.innerHeight) / height;
        if (scrollPercent > 0.78 && !this.state.showing && !this.state.shown) {
            localStorage.setItem(OPTIN_LOCAL_KEY, new Date().getTime().toString());
            this.setState({ showing: true, shown: true });
        }
    }

    render() {
        const { isOpen, onClose, children } = this.props;
        return (
            <Modal
                isOpen={isOpen || this.state.showing}
                contentLabel="Subscribe"
                onClose={() => {
                    if (window.gtag) {
                        window.gtag('event', "optin-signup-close", {
                            'event_category' : 'optin-signup-form',
                            'event_label' : "Close opt-in popup"
                        });
                    }
                    if (this.state.showing) {
                        this.setState({ showing: false });
                    }
                    if (onClose) {
                        onClose();
                    }
                }}
                closeBtnColor="#bbb"
            >
                {children}
            </Modal>
        );
    }
}
 
export default withTranslate(OptInPopup);
