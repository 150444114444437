import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from './Button';

import { cn } from '../../util';

import styles from "./Modal.module.css";

const ReactModal = require('react-modal');

export interface ModalProps {
    contentLabel: string,
    isOpen: boolean,
    onClose: () => void,
    image?: {
        src: string,
        alt: string,
    },
    closeBtnColor?: string,
    className?: string,
    contentClassName?: string,
}

const Modal: React.SFC<ModalProps> = ({ contentLabel, isOpen, onClose, image, closeBtnColor, className, contentClassName, children }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            contentLabel={contentLabel}
            onRequestClose={onClose}
            className={cn(styles.modal, className)}
            closeTimeoutMS={300}
        >
            <div className={cn(styles.body, contentClassName)}>
                {image &&
                    <img className={styles.modalImg} src={image.src} alt={image.alt}/>
                }
                <div className={styles.content}>
                    {children}
                </div>
                <Button
                    className={styles.closeBtn}
                    style={closeBtnColor ? { color: closeBtnColor } : undefined}
                    onClick={onClose}
                ><FontAwesomeIcon icon="times"/></Button>
            </div>
        </ReactModal>
    );
}

export default Modal;
