import React, { Component } from 'react';
import { debounce } from '../../util';

class AutoPlayInViewVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            showControls: false,
        };
        this.videoRef = React.createRef();
    }
    componentDidMount() {
        this.scrollListener = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.scrollListener);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListener);
        if (this.startTimer) {
            clearTimeout(this.startTimer);
        }
    }
    handleScroll = () => {
        let visiblePercent = this.isElementInViewport(this.videoRef.current);
        if (this.state.playing) {
            if (visiblePercent < 0.75) {
                this.setState({ playing: false }, () => {
                    this.videoRef.current.pause();
                });
            }
        } else if (visiblePercent > 0.8 && !this.startTimer) {
            this.startTimer = setTimeout(() => {
                this.setState({ playing: true }, () => {
                    this.startTimer = undefined;
                    this.videoRef.current.play();
                });
            }, 800);
        }
    }
    isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        const visibleBottom = Math.max(0, Math.min(rect.bottom, (window.innerHeight || document.documentElement.clientHeight)));
        const visibleTop = Math.max(0, rect.top);
        const visibleHeight = visibleBottom - visibleTop;
        // Return visible percentage
        return visibleHeight / rect.height;
    }
    render() { 
        return (
            <video ref={this.videoRef} playsInline muted loop controls={this.state.showControls}
                onMouseEnter={() => { this.setState({ showControls: true }); }}
                onMouseLeave={() => { this.setState({ showControls: false }); }}
                style={this.props.style}
                className={"img-shadow" + (this.props.className ? ` ${this.props.className}` : "")}
                style={{ boxSizing: "border-box", maxWidth: "100%" }}
            >
                {this.props.children}
            </video>
        );
    }
}
 
export default AutoPlayInViewVideo;
