import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from './Button';

import withTranslate from '../translate';
import { cn } from '../../util';

import styles from "./HelloBar.module.css";
import processText from '../../views/processText';
import OptInPopup from './OptInPopup';
import OptInThankYouModal from './OptInThankYouModal';

class HelloBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showing: false,
            showPopup: false,
            showThankYou: false,
        };
    }
    componentDidMount() {
        this.showTimeout = setTimeout(() => {
            this.showTimeout = undefined;
            this.setState({ showing: true });
        }, 1500);
    }
    componentWillUnmount() {
        if (this.showTimeout) {
            clearTimeout(this.showTimeout);
            this.showTimeout = undefined;
        }
    }
    render() {
        const { translate } = this.props;
        return (
            <div className={cn(styles.hellobar, this.state.showing ? "" : styles.hide)}>
                <div className={cn(styles.hellobarContainer)}>
                    <div className={styles.textColumn}>
                        {processText(translate("hellobar.text"))}
                    </div>
                    <div className={styles.actionColumn}>
                        <Button sm
                            className={styles.actionButton}
                            onClick={() => {
                            if (window.gtag) {
                                window.gtag('event', `hello-action`, {
                                    'event_category' : 'hello-bar',
                                    'event_label' : `Click hello bar action`,
                                });
                            }
                            this.setState({ showPopup: true });
                        }}>
                            <div style={{ whiteSpace: "nowrap" }}>{translate("hellobar.action")}</div>
                        </Button>
                    </div>
                </div>
                <div className={styles.closeColumn}>
                    <Button sm
                        title={translate("common.button.close")}
                        onClick={() => {
                            if (window.gtag) {
                                window.gtag('event', `hello-close`, {
                                    'event_category' : 'hello-bar',
                                    'event_label' : `Close hello bar`,
                                });
                            }
                            this.setState({ showing: false });
                        }}>
                        <FontAwesomeIcon icon="times" />
                    </Button>
                </div>
                <OptInPopup
                    isOpen={this.state.showPopup}
                    onClose={() => {
                        this.setState({ showPopup: false });
                    }}
                >
                    {this.props.renderForm({
                        onSubscribe: () => {
                            this.setState({ showPopup: false, showThankYou: true });
                        },
                    })}
                </OptInPopup>
                <OptInThankYouModal
                    isOpen={this.state.showThankYou}
                    onClose={() => {
                        this.setState({ showThankYou: false });
                    }}
                    translate={translate}
                />
            </div>
        );
    }
}

export default withTranslate(HelloBar);
