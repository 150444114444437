import * as React from 'react';
import axios from "axios";

import EditText from './EditText';
import Button from './Button';

import { validateEmail } from "../../views/SignUpPage/SignUpForm";

const MIN_COMPANY_LENGTH = 3;

export interface SignupData {
    company: string,
    companyName: string,
    name: string,
    email: string,
}

export interface OptInFormProps {
    companyRequired?: boolean,
    translate: (key: string) => string,
    onSubscribe?: (responseBody: any) => void,
    signUpUrl: string,
    signupFrom?: string,
    getSignupData?: (data: SignupData) => any,
    error?: {
        [key: string]: string | undefined,
        company?: string,
        name?: string,
        email?: string,
        signup?: string,
    },
}

export interface OptInFormState {
    company: string,
    name: string,
    email: string,
    sending: boolean,
    error?: {
        [key: string]: string | undefined,
        company?: string,
        name?: string,
        email?: string,
        signup?: string,
    },
    botSignup: boolean,
}

interface OptInContextProps {
    values: {
        [field: string]: string,
    },
    error?: {
        [key: string]: string | undefined,
    },
    sending: boolean,
    onChange: (update: object) => void,
    translate: (key: string) => string,
}
const defaultOptInContext: OptInContextProps = {
    values: {},
    sending: false,
    onChange: () => {},
    translate: (key) => key
};
const OptInContext = React.createContext<OptInContextProps>(defaultOptInContext);

export interface FieldProps {
    fieldName: "company" | "name" | "email",
    className?: string,
    style?: React.CSSProperties,
}
export type NamedFieldProps = Omit<FieldProps, "fieldName">;

export class TextField extends React.Component<FieldProps> {
    renderWithContext = (context: OptInContextProps) => {
        const { fieldName, style, className } = this.props;
        const title = context.translate(`common.forms.placeholder.${fieldName}`);
        return (
            <EditText
                name={fieldName}
                error={context.error && context.error[fieldName] ? context.error[fieldName] : undefined}
                required={true}
                style={style}
                className={className}
                title={title}
                placeholder={title}
                onChange={(value) => { context.onChange({ [fieldName]: value }); }}
            />
        );
    }
    render() {
        return (
            <OptInContext.Consumer>
                {this.renderWithContext}
            </OptInContext.Consumer>
        );
    }
}

export const CompanyField: React.SFC<NamedFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            fieldName="company"
        />
    );
}

export const NameField: React.SFC<NamedFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            fieldName="name"
        />
    );
}

export const EmailField: React.SFC<NamedFieldProps> = (props) => {
    return (
        <TextField
            {...props}
            fieldName="email"
        />
    );
}

export interface SubmitButtonProps {
    className?: string,
    style?: React.CSSProperties,
    actionText: string,
    loadingText: string,
}

export const SubmitButton: React.SFC<SubmitButtonProps> = ({ className, style, actionText, loadingText }) => {
    return (
        <OptInContext.Consumer>
            {(context) => (
                <Button submit disabled={context.sending} className={className} style={style}>
                    {context.sending ? loadingText : actionText}
                </Button>
            )}
        </OptInContext.Consumer>
    );
}

export const ErrorLabel: React.SFC = () => {
    return (
        <OptInContext.Consumer>
            {(context) => {
                let err = context.error && context.error.signup ? context.error.signup : undefined;
                if (err) {
                    return (
                        <div className="text-danger small">
                            {context.error && context.error.signup}
                        </div>
                    );
                }
                return null;
            }}
        </OptInContext.Consumer>
    );
}

class OptInForm extends React.Component<OptInFormProps, OptInFormState> {
    constructor(props: OptInFormProps) {
        super(props);
        this.state = {
            company: "",
            name: "",
            email: "",
            sending: false,
            botSignup: false,
        }
    }

    onChange = (update: object) => {
        this.setState({ error: undefined, ...update });
    }

    onSubmit = (ev: any) => {
        ev.preventDefault();
        if (this.state.sending) return;

        let error;
        const name = this.state.name.trim();
        const email = this.state.email.trim().toLowerCase();
        const companyName = this.state.company.trim();
        const company = companyName.toLowerCase().replace(/[^a-z]/g, "");
        if (this.props.companyRequired) {
            if (!companyName) {
                error = Object.assign({}, error, { company: this.props.translate("common.forms.error.companyRequired") });
            } else if (company.length < MIN_COMPANY_LENGTH) {
                error = Object.assign({}, error, { company: this.props.translate("common.forms.error.companyNameTooShort") });
            }
        }
        if (!name) {
            error = Object.assign({}, error, { name: this.props.translate("common.forms.error.nameRequired") });
        }
        if (!email) {
            error = Object.assign({}, error, { email: this.props.translate("common.forms.error.emailRequired") });
        } else if (!validateEmail(email)) {
            error =  Object.assign({}, error, { email: this.props.translate("common.forms.error.emailInvalid") });
        }
        if (this.state.botSignup) {
            error =  Object.assign({}, error, { signup: this.props.translate("common.forms.error.internal") });
        }

        if (error) {
            this.setState({ error });
        } else {
            if ((window as any).gtag) {
                (window as any).gtag('event', "optin-signup-accept", {
                    'event_category' : 'optin-signup-form',
                    'event_label' : `Accept opt-in and sign up ${this.props.signupFrom || ""}`
                });
            }
            let signupData = {
                company,
                companyName,
                name,
                email,
            };
            if (this.props.getSignupData) {
                signupData = this.props.getSignupData(signupData);
            }
            this.setState({ sending: true });
            axios.post(this.props.signUpUrl, signupData)
            .then((res) => {
                if (res.status >= 200 && res.status < 300) {
                    if (this.props.onSubscribe) {
                        this.props.onSubscribe(res.data);
                    }
                    this.setState({ sending: false });
                } else {
                    if ((window as any).gtag) {
                        (window as any).gtag('event', "optin-signup-error", {
                            'event_category' : 'optin-signup-form',
                            'event_label' : "Signup failed with response " + res.status,
                        });
                    }
                    this.setState({
                        sending: false,
                        error: Object.assign({}, this.state.error, { signup: this.props.translate("common.forms.error.internal") }),
                    });
                }
            })
            .catch((err) => {
                if ((window as any).gtag) {
                    (window as any).gtag('event', "optin-signup-error", {
                        'event_category' : 'optin-signup-form',
                        'event_label' : "Signup failed" + (err.response ? ` with response ${err.response.status}` : ""),
                    });
                }
                this.setState({
                    sending: false,
                    error: Object.assign({}, this.state.error, { signup: this.props.translate("common.forms.error.connection") }),
                });
            });
        }
    }

    getError = (name: string) => {
        if (this.state.error && this.state.error[name]) {
            return this.state.error[name];
        } else if (this.props.error && this.props.error[name]) {
            return this.props.error[name];
        }
        return undefined;
    }

    render() {
        const { translate } = this.props;
        return (
            <form onSubmit={this.onSubmit}>
                <OptInContext.Provider value={{
                    values: {
                        company: this.state.company,
                        name: this.state.name,
                        email: this.state.email,
                    },
                    error: this.state.error,
                    sending: this.state.sending,
                    onChange: this.onChange,
                    translate,
                }}>
                    {this.props.children}
                </OptInContext.Provider>
            </form>
        );
    }
}

export default OptInForm;
